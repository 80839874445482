import {
  ButtonHTMLAttributes,
  CSSProperties,
  DetailedHTMLProps,
  PropsWithChildren,
} from 'react';
import cx from 'classnames';
import styles from './index.module.css';
import { getContrastingColor } from '@/themes/utils/colorUtils';
import { useTheme } from '@mui/material';

interface BaseButtonSimpleProps
  extends PropsWithChildren,
    DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    > {
  color?: 'primary' | 'secondary' | 'white';
  variant?: 'contained' | 'outlined' | 'text';
  size?: 'small' | 'medium' | 'large';
  type?: 'submit' | 'button';
  fullWidth?: boolean;
}

export default function BaseButtonSimple({
  children,
  size = 'medium',
  fullWidth = false,
  className,
  ...props
}: BaseButtonSimpleProps) {
  const theme = useTheme();

  const backgroundColor =
    props.color === 'secondary'
      ? theme.palette.secondary.main
      : theme.palette.primary.main;
  const contrastingColor = getContrastingColor(backgroundColor);

  let textColor =
    contrastingColor === '#fff' ? 'text-white' : 'text-wx-neutral-900';
  if (props.disabled) textColor = 'text-wx-neutral-400';

  const defaultProps = {
    type: 'button',
    className: cx(
      styles.ButtonSimple,
      'inline-flex items-center justify-center gap-2 max-w-full',
      {
        [styles['ButtonSimple-colorPrimary']]:
          !props.color || props.color === 'primary',
        [styles['ButtonSimple-colorSecondary']]: props.color === 'secondary',
        [styles['ButtonSimple-variantContained']]:
          props.variant === 'contained',

        'bg-wx-neutral-200 pointer-events-none': props.disabled,

        'w-full': fullWidth,

        'py-2 px-4': size === 'medium',
        'py-1.5 px-2': size === 'small',
        'py-4 px-4': size === 'large',
      },
      textColor,
      className,
    ),
  } as BaseButtonSimpleProps;

  return (
    <button
      style={
        {
          ['--color-wx-primary']: theme.palette.primary.main,
          ['--color-wx-secondary']: theme.palette.secondary.main,
        } as CSSProperties
      }
      {...defaultProps}
      {...props}
    >
      <div className="max-w-full break-words">{children}</div>
    </button>
  );
}
