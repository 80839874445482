import { cx } from '@/themes/utils/cssUtils';
import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  PropsWithChildren,
} from 'react';

interface BaseButtonTextProps
  extends PropsWithChildren,
    DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    > {
  color?: 'primary' | 'secondary' | 'white';
  size?: 'small' | 'medium' | 'large';
  type?: 'submit' | 'button';
  noPadding?: boolean;
}

export default function BaseButtonText({
  children,
  size = 'medium',
  className,
  noPadding,
  ...props
}: BaseButtonTextProps) {
  const isPrimary = !props.color || props.color === 'primary';
  const isSecondary = props.color === 'secondary';

  let textColor = 'text-wx-neutral-900';
  if (isPrimary) textColor = 'text-wx-primary';
  if (isSecondary) textColor = 'text-wx-secondary';
  if (props.disabled) textColor = 'text-wx-neutral-400';

  const defaultProps = {
    type: 'button',
    className: cx(
      'text-base inline-flex font-medium text-wx-brand-600 border border-transparent leading-snug uppercase text-sm',
      {
        'hover:brightness-50': !props.disabled,
        'pointer-events-none': props.disabled,

        'py-2 px-4': size === 'medium' && !noPadding,
        'py-1.5 px-2': size === 'small' && !noPadding,
        'py-4 px-4': size === 'large' && !noPadding,
      },
      textColor,
      className,
    ),
  } as BaseButtonTextProps;

  return (
    <button {...defaultProps} {...props}>
      {children}
    </button>
  );
}
