type RGBColor = [number, number, number];

/**
 * Converts a hexadecimal color code to an RGB array.
 *
 * @param {string} hex - The hexadecimal color code (e.g., "#ffffff").
 * @returns {RGBColor} The RGB representation of the color as an array [r, g, b].
 */
export function hexToRgb(hexColor: string): RGBColor {
  let cleanHex = `${hexColor}`.replace(/^#/, '');

  // If hex is in the form FFF, change it to FFFFFF
  if (cleanHex.length === 3) {
    cleanHex = cleanHex
      .split('')
      .map(c => `${c}${c}`)
      .join('');
  }

  const r = parseInt(cleanHex.slice(0, 2), 16);
  const g = parseInt(cleanHex.slice(0, 2), 16);
  const b = parseInt(cleanHex.slice(0, 2), 16);

  return [r, g, b];
}

/**
 * Calculates the contrast ratio between two colors.
 *
 * @param {string} foregroundHexColor - The hexadecimal color code of the first color.
 * @param {string} backgroundHexColor - The hexadecimal color code of the second color.
 * @returns {number} The contrast ratio between the two colors between 1 and 21 (WCAG).
 */
export function getContrastRatio(
  foregroundHexColor: string,
  backgroundHexColor: string,
): number {
  const foregroundColor = hexToRgb(foregroundHexColor);
  const backgroundColor = hexToRgb(backgroundHexColor);

  const luminance = (color: RGBColor) => {
    const [r, g, b] = color.map(value => {
      value /= 255.0;
      return value <= 0.03928
        ? value / 12.92
        : Math.pow((value + 0.055) / 1.055, 2.4);
    });

    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  };

  const foregroundLuminance = luminance(foregroundColor);
  const backgroundLuminance = luminance(backgroundColor);

  const contrastRatio =
    (Math.max(foregroundLuminance, backgroundLuminance) + 0.05) /
    (Math.min(foregroundLuminance, backgroundLuminance) + 0.05);

  return contrastRatio;
}

/**
 * Find whether black/white is the better contrasting color.
 * @param {string} hexColor - The color from which we want the contrasting color
 */
export function getContrastingColor(hexColor: string): '#000' | '#fff' {
  const [r, g, b] = hexToRgb(hexColor);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? '#000' : '#fff';
}
